import { type } from '@testing-library/user-event/dist/type';
import * as actionTypes from '../../constants/analytics.constant';

// Toggle sidebar
export const toggleSidebarMenu = () => ({
    type: actionTypes.TOGGLE_SIDEBAR_MENU
})
export const CTCFullHiveTogglemenu = (data) => ({
    type: actionTypes.TOGGLE_CTC_FULL_HIVE_HENDER_BTN,
    data
})// This Toggle For Only practice User 48 TextCenter Id

export const CTCSchedulingOnlyTogglemenu = (data) => ({
    type: actionTypes.TOGGLE_CTC_SCHEDULING_ONLY_BTN,
    data
})
export const CTCClickToCallTogglemenu = (data) => ({
    type: actionTypes.TOGGLE_CTC_CLICK_TO_CALL_BTN,
    data
})

export const businesshoursBtnTogglemenu = (data) => ({
    type: actionTypes.BUSINESS_HOURS_BTN,
    data
})
export const CTCSelectedToggle = (payload) => ({
    type: actionTypes.CTT_SELECTED_TOGGLE_PAYLOAD,
    payload
})


// Toggle header
export const toggleTopHeader = () => ({
    type: actionTypes.TOGGLE_TOP_HEADER
})

//DotsOption
export const toggleDotsOption = (data) => ({
    type: actionTypes.DOTS_OPTION,
    data
})

// toggle header btn
export const onclickHeaderToggle = (data) => ({
    type: actionTypes.TOGGLE_HEADER_BTN,
    data
})

// Authentication
export const authenticationRequest = () => ({
    type: actionTypes.AUTHENTICATION_REQUEST
})

export const authenticationResponse = (data) => ({
    type: actionTypes.AUTHENTICATION_RESPONSE,
    data
})

export const authenticationError = (error) => ({
    type: actionTypes.AUTHENTICATION_ERROR,
    error
})

export const getBssUsersViewResponse = (data) => ({
    type: actionTypes.GET_BSS_USERS_VIEW_RESPONSE,
    data
})

// Get text center array
export const getTextCenterIdRequest = () => ({
    type: actionTypes.GET_TEXT_CENTER_REQUEST
})

export const getTextCenterIdResponse = (data) => ({
    type: actionTypes.GET_TEXT_CENTER_RESPONSE,
    data
})

export const getTextCenterIdError = (error) => ({
    type: actionTypes.GET_TEXT_CENTER_ERROR,
    error
})

// Get Common Leads Option Array
export const getCommonLeadsOptionRequest = () => ({
    type: actionTypes.GET_COMMON_LEADS_OPTION_REQUEST
})

export const getCommonLeadsOptionResponse = (data) => ({
    type: actionTypes.GET_COMMON_LEADS_OPTION_RESPONSE,
    data
})

export const getCommonLeadsOptionError = (error) => ({
    type: actionTypes.GET_COMMON_LEADS_OPTION_ERROR,
    error
})

export const getCommonWebPagesOptionResponse = (data) => ({
    type: actionTypes.GET_COMMON_WEB_PAGES_OPTION_RESPONSE,
    data
})

export const getCommonInsurancePlansOptionResponse = (data) => ({
    type: actionTypes.GET_COMMON_INSURANCE_PLANS_OPTION_RESPONSE,
    data
})

// get Procedures option array
export const getHeaderProceduresOptionResponse = (data) => ({
    type: actionTypes.GET_HEADER_PROCEDURES_OPTION_RESPONSE,
    data
})

export const getHeaderProceduresOptionError = (error) => ({
    type: actionTypes.GET_HEADER_PROCEDURES_OPTION_ERROR,
    error
})

// Get Leads Option Array
export const getLeadsOptionRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_REQUEST
})

export const getLeadsOptionResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_RESPONSE,
    data
})

export const getLeadsOptionError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_ERROR,
    error
})

// Get Leads Insurance First Option Array
export const getLeadsInsuranceFirstOptionRequest = () => ({
    type: actionTypes.GET_LEADS_INSURANCE_FIRST_OPTION_REQUEST
})

export const getLeadsInsuranceFirstOptionResponse = (data) => ({
    type: actionTypes.GET_LEADS_INSURANCE_FIRST_OPTION_RESPONSE,
    data
})

export const getLeadsInsuranceFirstOptionError = (error) => ({
    type: actionTypes.GET_LEADS_INSURANCE_FIRST_OPTION_ERROR,
    error
})

// Chart Analytics data
export const getChartAnalyticsRequest = (payload) => ({
    type: actionTypes.GET_CHART_ANALYTICS_REQUEST,
    payload
})

export const getChartAnalyticsResponse = (response) => ({
    type: actionTypes.GET_CHART_ANALYTICS_RESPONSE,
    response
})

export const getChartAnalyticsError = (error) => ({
    type: actionTypes.GET_CHART_ANALYTICS_ERROR,
    error
})

// get UTM toggle Option Array 

export const getUtmOptionRequest = (data) => (
    {
        type: actionTypes.GET_UTM_OPTIONS_RESPONSE,
        data
    }

)

// ! --------------------------------- Line Chart ---------------------------------
// MultiLineChart Analytics data
export const getMultiLineChartAnalyticsRequest = () => ({
    type: actionTypes.GET_MULTILINE_CHART_ANALYTICS_REQUEST
})

export const getMultiLineChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_MULTILINE_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getMultiLineChartAnalyticsError = (error) => ({
    type: actionTypes.GET_MULTILINE_CHART_ANALYTICS_ERROR,
    error
})

// Showed & Upcoming Consult Report
export const getShowedAndUpcomingConsultReportRequest = () => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_REQUEST
})

export const getShowedAndUpcomingConsultReportResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_RESPONSE,
    response,
    payload
})

export const getShowedAndUpcomingConsultReportError = (error) => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_ERROR,
    error
})

// toggle Combine Leads Toggle
export const onclickCombineLeadsToggle = (data) => ({
    type: actionTypes.TOGGLE_COMBINE_LEADS_BTN,
    data
})

// Showed & Upcoming Consult Appointment
export const getShowedAndUpcomingConsultAppointmentRequest = () => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_REQUEST
})

export const getShowedAndUpcomingConsultAppointmentResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_RESPONSE,
    response,
    payload
})

export const getShowedAndUpcomingConsultAppointmentError = (error) => ({
    type: actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_ERROR,
    error
})

// daily_open_action_items_report
export const getdailyOpenActionItemsReportRequest = () => ({
    type: actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_REQUEST
})

export const getdailyOpenActionItemsReportResponse = (response, payload) => ({
    type: actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_RESPONSE,
    response,
})

export const getdailyOpenActionItemsReportError = (error) => ({
    type: actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_ERROR,
    error
})

// toggle Combine Leads Appointment Toggle
export const onclickCombineLeadsAppointmentToggle = (data) => ({
    type: actionTypes.TOGGLE_COMBINE_LEADS_APPOINTMENT_BTN,
    data
})

// getAvaliableAppointment; 2nd tab 1st line chart
export const getAvaliableAppointmentLineChartRequest = () => ({
    type: actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_REQUEST
})

export const getAvaliableAppointmentLineChartResponse = (response, payload) => ({
    type: actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_RESPONSE,
    response,
    payload
})

export const getAvaliableAppointmentLineChartError = (error) => ({
    type: actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_ERROR,
    error
})

// Get Leads Option Array
export const getLeadsOptionAvaliableAppointmentsRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_AVALIABLE_APPOINTMENT_REQUEST
})

export const getLeadsOptionAvaliableAppointmentsResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_AVALIABLE_APPOINTMENT_RESPONSE,
    data
})

export const getLeadsOptionAvaliableAppointmentsError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_AVALIABLE_APPOINTMENT_ERROR,
    error
})

// ! Multivalue Axis data (multiple y axis)
export const getWebsiteConversionRequest = () => ({
    type: actionTypes.GET_WEBSITE_CONVERSION_REQUEST
})

export const getWebsiteConversionResponse = (response, payload) => ({
    type: actionTypes.GET_WEBSITE_CONVERSION_RESPONSE,
    response,
    payload
})

export const getWebsiteConversionError = (error) => ({
    type: actionTypes.GET_WEBSITE_CONVERSION_ERROR,
    error
})

//Get Alerts Data
export const getalertRequest = () => ({
    type: actionTypes.GET_ALERTS_REQUEST
})

export const alertResponseHandler = (response, payload) => ({
    type: actionTypes.GET_ALERTS_RESPONSE,
    response,
    payload
})

export const alertResponseHandlerError = (error) => ({
    type: actionTypes.GET_ALERTS_ERROR,
    error
})

export const alertCount = (response, payload) => {
    return {
        type: actionTypes.GET_ALERTS_COUNT,
        response,
    }
}


// ! --------------------------------- Pyramid Chart ---------------------------------
// ScheduleFirst Pyramid Chart Analytics data
export const getScheduleFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getScheduleFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getScheduleFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// InsuranceFirst Pyramid Chart Analytics data
export const getInsuranceFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getInsuranceFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getInsuranceFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// ! --------------------------------- Gauge Chart ---------------------------------
// Get Practice Location Api Call
export const getPracticeLocationsApiRequest = () => ({
    type: actionTypes.GET_PRACTICE_LOCATIONS_API_REQUEST
})
export const getPracticeLocationsApiResponse = (response, payload) => ({
    type: actionTypes.GET_PRACTICE_LOCATIONS_API_RESPONSE,
    response,
    payload
})
export const getPracticeLocationsApiError = (error) => ({
    type: actionTypes.GET_PRACTICE_LOCATIONS_API_ERROR,
    error
})
// Get Surgeons Api Call
export const getSurgeonsApiRequest = () => ({
    type: actionTypes.GET_SURGEONS_API_REQUEST
})
export const getSurgeonsApiResponse = (response, payload) => ({
    type: actionTypes.GET_SURGEONS_API_RESPONSE,
    response,
    payload
})
export const getSurgeonsApiError = (error) => ({
    type: actionTypes.GET_SURGEONS_API_ERROR,
    error
})
// Get Gauge Chart Api Call
export const getGaugeChartApiRequest = () => ({
    type: actionTypes.GET_GAUGECHART_API_REQUEST
})
export const getGaugeChartApiResponse = (response, payload) => ({
    type: actionTypes.GET_GAUGECHART_API_RESPONSE,
    response,
    payload
})
export const getGaugeChartApiError = (error) => ({
    type: actionTypes.GET_GAUGECHART_API_ERROR,
    error
})

// ! --------------------------------- Heat Map Chart ---------------------------------
// Booked Appointment HeatMap Chart API Call
export const getBookedAppointmentHeatMapChartAnalyticsRequest = () => ({
    type: actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST
})
export const getBookedAppointmentHeatMapChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})
export const getBookedAppointmentHeatMapChartAnalyticsError = (error) => ({
    type: actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR,
    error
})

export const getSelectedLocationBookedResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_BOOKED_RESPONSE,
    data
})

// Get Leads Option For Booked Heat Map chart
export const getLeadsOptionBookedRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_BOOKED_REQUEST
})

export const getLeadsOptionBookedResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_BOOKED_RESPONSE,
    data
})

export const getLeadsOptionBookedError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_BOOKED_ERROR,
    error
})

// Showed Appointment HeatMap Chart API Call
export const getShowedAppointmentHeatMapChartAnalyticsRequest = () => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST
})
export const getShowedAppointmentHeatMapChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})
export const getShowedAppointmentHeatMapChartAnalyticsError = (error) => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR,
    error
})

// // Get Leads Option For Showed Heat Map chart
// export const getLeadsOptionShowedRequest = () => ({
//     type: actionTypes.GET_LEADS_OPTION_SHOWED_REQUEST
// })

export const getSelectedLocationShowedResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_SHOWED_RESPONSE,
    data
})

// export const getLeadsOptionShowedError = (error) => ({
//     type: actionTypes.GET_LEADS_OPTION_SHOWED_ERROR,
//     error
// })

// Get Leads Option For Showed Heat Map chart
export const getLeadsOptionShowedRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_SHOWED_REQUEST
})

export const getLeadsOptionShowedResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_SHOWED_RESPONSE,
    data
})

export const getLeadsOptionShowedError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_SHOWED_ERROR,
    error
})

// Show Rate Appointment HeatMap Chart API Call
export const getShowRateAppointmentHeatMapChartAnalyticsRequest = () => ({
    type: actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST
})
export const getShowRateAppointmentHeatMapChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})
export const getShowRateAppointmentHeatMapChartAnalyticsError = (error) => ({
    type: actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR,
    error
})

export const getSelectedLocationShowRateResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_SHOW_RATE_RESPONSE,
    data
})
// Get Leads Option For ShowRate Heat Map chart
export const getLeadsOptionShowRateRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_SHOW_RATE_REQUEST
})

export const getLeadsOptionShowRateResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_SHOW_RATE_RESPONSE,
    data
})

export const getLeadsOptionShowRateError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_SHOW_RATE_ERROR,
    error
})

// ! --------------------------------- Funnel Chart ---------------------------------
// Insurance Report Funnel Chart
export const getInsuranceReportFunnelChartAnalyticsRequest = () => ({
    type: actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_REQUEST
})

export const getInsuranceReportFunnelChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getInsuranceReportFunnelChartAnalyticsError = (error) => ({
    type: actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_ERROR,
    error
})

// Get Leads Option For Insurance Funnel Chart
export const getLeadsOptionInsuranceFunnelRequest = () => ({
    type: actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_REQUEST
})

export const getLeadsOptionInsuranceFunnelResponse = (data) => ({
    type: actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_RESPONSE,
    data
})

export const getLeadsOptionInsuranceFunnelError = (error) => ({
    type: actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_ERROR,
    error
})


// ! --------------------------------- Phone Flow ---------------------------------
// ScheduleFirst Pyramid Chart Analytics data
export const getPhoneFlowScheduleFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getPhoneFlowScheduleFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getPhoneFlowScheduleFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// InsuranceFirst Pyramid Chart Analytics data
export const getPhoneFlowInsuranceFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getPhoneFlowInsuranceFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getPhoneFlowInsuranceFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// ! -------------------------------- Google Analytics --------------------------------
export const getShowedAppointmentGoogleAnalyticsRequest = () => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_REQUEST
})

export const getShowedAppointmentGoogleAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_RESPONSE,
    response,
    payload
})

export const getShowedAppointmentGoogleAnalyticsError = (error) => ({
    type: actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_ERROR,
    error
})

// ! --------------------------------- Referral Report ---------------------------------
// Get ScheduleFirst Pyramid Chart Analytics data
export const getReferralReportScheduleFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getReferralReportScheduleFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getReferralReportScheduleFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// Get InsuranceFirst Pyramid Chart Analytics data
export const getReferralReportInsuranceFirstPyramidChartAnalyticsRequest = () => ({
    type: actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST
})

export const getReferralReportInsuranceFirstPyramidChartAnalyticsResponse = (response, payload) => ({
    type: actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE,
    response,
    payload
})

export const getReferralReportInsuranceFirstPyramidChartAnalyticsError = (error) => ({
    type: actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR,
    error
})

// ! --------------------------------- Bar Chart ---------------------------------
// Get InsuranceFirst Pyramid Chart Analytics data
export const getClickedSchedulingBarChartRequest = () => ({
    type: actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_REQUEST
})

export const getClickedSchedulingBarChartResponse = (response, payload) => ({
    type: actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_RESPONSE,
    response,
    payload
})

export const getClickedSchedulingBarChartError = (error) => ({
    type: actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_ERROR,
    error
})

export const getSelectedLocationClickedBarResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_CLICKED_BAR_RESPONSE,
    data
})

// ! --------------------------------- Stacked Column Chart ---------------------------------
// TODO: 100% Stacked Column Chart
export const getfilledVsUnfilledAppointmentRequest = () => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_REQUEST
})

export const getfilledVsUnfilledAppointmentResponse = (response, payload) => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_RESPONSE,
    response,
    payload
})

export const getfilledVsUnfilledAppointmentError = (error) => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_ERROR,
    error
})

export const getSelectedLocationFilledVsUnfilledResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_FILLED_VS_UNFILLED_RESPONSE,
    data
})

// TODO: Stacked Column Chart
// Showed Consults
export const getShowedConsultsByLeadSourceRequest = () => ({
    type: actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_REQUEST
})

export const getShowedConsultsByLeadSourceResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_RESPONSE,
    response,
    payload
})

export const getShowedConsultsByLeadSourceError = (error) => ({
    type: actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_ERROR,
    error
})

export const getSelectedLocationShowedConsultsByLeadSourceResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_SHOWED_CONSULTS_BY_LEAD_RESPONSE,
    data
})

// Upcoming Consults
export const getUpcomingConsultsByLeadSourceRequest = () => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_REQUEST
})

export const getUpcomingConsultsByLeadSourceResponse = (response, payload) => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_RESPONSE,
    response,
    payload
})

export const getUpcomingConsultsByLeadSourceError = (error) => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_ERROR,
    error
})

export const getSelectedLocationUpcomingConsultsByLeadSourceResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_UPCOMING_CONSULTS_BY_LEAD_RESPONSE,
    data
})

// Get Showed Vs NoShow Vs Cancelled Column Chart
export const getShowedVsNoShowVsCancelledAppointmentRequest = () => ({
    type: actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_REQUEST
})

export const getShowedVsNoShowVsCancelledAppointmentResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_RESPONSE,
    response,
    payload
})

export const getShowedVsNoShowVsCancelledAppointmentError = (error) => ({
    type: actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_ERROR,
    error
})

export const getSelectedLocationShowedVsNoShowVsCancelledResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_SHOWED_VS_NOSHOW_VS_CANCELLED_RESPONSE,
    data
})

// ! --------------------------------- Pie Chart ---------------------------------
// Showed Consults pie chart
export const getShowedConsultsPieChartRequest = () => ({
    type: actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_REQUEST
})

export const getShowedConsultsPieChartResponse = (response, payload) => ({
    type: actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_RESPONSE,
    response,
    payload
})

export const getShowedConsultsPieChartError = (error) => ({
    type: actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_ERROR,
    error
})

//filled_vs_unfilled_future_appointment_slots_PieChart
export const getFilledVsUnfilledFutureAppointmentSlotsPieChartRequest = () => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_REQUEST
})

export const getFilledVsUnfilledFutureAppointmentSlotsPieChartResponse = (response, payload) => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_RESPONSE,
    response,
    payload
})

export const getFilledVsUnfilledFutureAppointmentSlotsPieChartError = (error) => ({
    type: actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_ERROR,
    error
})


export const getSelectedLocationShowedConsultsPieChartResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_SHOWED_CONSULTS_PIE_CHART_RESPONSE,
    data
})

// Upcoming Consults pie chart
export const getUpcomingConsultsPieChartRequest = () => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_REQUEST
})

export const getUpcomingConsultsPieChartResponse = (response, payload) => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_RESPONSE,
    response,
    payload
})

export const getUpcomingConsultsPieChartError = (error) => ({
    type: actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_ERROR,
    error
})

export const getSelectedLocationUpcomingConsultsPieChartResponse = (data) => ({
    type: actionTypes.GET_SELECTED_LOCATION_UPCOMING_CONSULTS_PIE_CHART_RESPONSE,
    data
})

// ! --------------------------------------------------------------------------------


// ! -------------------------------- Click To 1 --------------------------------
export const getClickToCallScheduledShowedConsultsRequest = () => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_REQUEST
})
export const getClickToCallScheduledShowedConsultsResponse = (response, payload) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_RESPONSE,
    response,
    payload
})
export const getClickToCallScheduledShowedConsultsError = (error) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_ERROR,
    error
})

// ! -------------------------------- Click To 2 --------------------------------
export const getClicktocallscheduledshowedconsultsbyleadsourceRequest = () => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_REQUEST
})
export const getClicktocallscheduledshowedconsultsbyleadsourceResponse = (response, payload) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_RESPONSE,
    response,
    payload
})
export const getClicktocallscheduledshowedconsultsbyleadsourceError = (error) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_ERROR,
    error
})

// ! -------------------------------- Click To 3 --------------------------------
export const getClicktocallscheduledshowedconsultsformsubmissiondateRequest = () => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_REQUEST
})
export const getClicktocallscheduledshowedconsultsformsubmissiondateResponse = (response, payload) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_RESPONSE,
    response,
    payload
})
export const getClicktocallscheduledshowedconsultsformsubmissiondateError = (error) => ({
    type: actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_ERROR,
    error
})

// ! -------------------------------- Click To 4 --------------------------------
export const getClicktocallfunnelRequest = () => ({
    type: actionTypes.GET_CLICK_TO_CALL_FUNNEL_REQUEST
})
export const getClicktocallfunnelResponse = (response, payload) => ({
    type: actionTypes.GET_CLICK_TO_CALL_FUNNEL_RESPONSE,
    response,
    payload
})
export const getClicktocallfunnelError = (error) => ({
    type: actionTypes.GET_CLICK_TO_CALL_FUNNEL_ERROR,
    error
})
// ! -------------------------------- Click To 5 --------------------------------
export const getClicktocallWebsiteConversionRequest = () => ({
    type: actionTypes.GET_CLICK_TO_CALL_WEBSITE_CONVERSION
})
export const getClicktocallWebsiteConversionResponse = (response, payload) => ({
    type: actionTypes.GET_CLICK_TO_CALL_WEBSITE_RESPONSE,
    response,
    payload
})
export const getClicktocallWebsiteConversionError = (error) => ({
    type: actionTypes.GET_CLICK_TO_CALL_WEBSITE_ERROR,
    error
})
// ! -------------------------------- Click To 5 --------------------------------

// ! -------------------------------- open_action_item_average_turn_around_time --------------------------------
export const getOpenActionItemAverageTurnAroundTimeRequest = () => ({
    type: actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_REQUEST
})
export const getOpenActionItemAverageTurnAroundTimeResponse = (response, payload) => ({
    type: actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_RESPONSE,
    response,
    payload
})
export const getOpenActionItemAverageTurnAroundTimeError = (error) => ({
    type: actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_ERROR,
    error
})



// ! -------------------------------- open_action_items_resolved_by_user --------------------------------

export const getOpenActionItemsResolvedByUserRequest = () => ({
    type: actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST
})

export const getOpenActionItemsResolvedByUserResponse = (response, payload) => ({
    type: actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_RESPONSE,
    response,
    payload
})

export const getOpenActionItemsResolvedByUserError = (error) => ({
    type: actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_ERROR,
    error
})



// ! -------------------------------- FOLLOW_UP_IMPACT_REPORT_CHART -------------------------------

export const getfollowUpImpactReportChartRequest = () => ({
    type: actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_REQUEST
})
export const getfollowUpImpactReportChartResponse = (response, payload) => ({
    type: actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_RESPONSE,
    response,
    payload
})
export const getfollowUpImpactReportChartError = (error) => ({
    type: actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_ERROR,
    error
})

// ! -------------------------------- facebook_ads_cost_per_conversion ------------------------------

export const getfacebookAdsCostPerConversionRequest = () => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_REQUEST
})
export const getfacebookAdsCostPerConversionResponse = (response, payload) => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_RESPONS,
    response,
    payload
})
export const getfacebookAdsCostPerConversionError = (error) => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_ERROR,
    error
})


// ! -------------------------------- facebook_ads_cost_per_conversion_funnel ------------------------------

export const getFacebookAdsCostPerConversionFunnelRequest = () => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST
})

export const getFacebookAdsCostPerConversionFunnelResponse = (response, payload) => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS,
    response,
    payload
})

export const getFacebookAdsCostPerConversionFunnelError = (error) => ({
    type: actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_ERROR,
    error
})


// ! -------------------------------- google_ads_cost_per_conversion ------------------------------

export const getGoogleAdsCostPerConversionRequest = () => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_REQUEST
})
export const getGoogleAdsCostPerConversionResponse = (response, payload) => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_RESPONS,
    response,
    payload
})
export const getGoogleAdsCostPerConversionError = (error) => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_ERROR,
    error
})



// ! -------------------------------- google_ads_cost_per_conversion_funnel ------------------------------

export const getGoogleAdsCostPerConversionFunnelRequest = () => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST
})

export const getGoogleAdsCostPerConversionFunnelResponse = (response, payload) => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS,
    response,
    payload
})

export const getGoogleAdsCostPerConversionFunnelError = (error) => ({
    type: actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_ERROR,
    error
})
// ! -------------------------------- Top_Performing_Ads --------------------------------------------------
export const getTopPerformingAdsRequest = () => ({
    type: actionTypes.GET_TOP_PERFORMING_ADS_REQUEST
})

export const getTopPerformingAdsResponse = (response) => ({
    type: actionTypes.GET_TOP_PERFORMING_ADS_RESPONSE,
    response,
})
export const getTopPerFormingAdsSelectedGoogleSourceOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS,
    FiltterSlectedValue,
})

export const getTopPerFormingAdsSelectedFacebookSourceOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS,
    FiltterSlectedValue,
})
export const getTopPerFormingAdsSelectedGropOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_GROP_OPTIONS,
    FiltterSlectedValue,
})

// ! -------------------------------- Non_Converting_Ads --------------------------------------------------
export const getNonConvertingAdsRequest = () => ({
    type: actionTypes.GET_NON_COVERTING_ADS_REQUEST
})

export const getNonConvertingAdsResponse = (response) => ({
    type: actionTypes.GET_NON_COVERTING_ADS_RESPONSE,
    response,
})

export const getNonConvertingAdsSelectedGoogleSourceOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_NON_COVERTING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS,
    FiltterSlectedValue,
})

export const getNonConvertingAdsSelectedFacebookSourceOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_NON_COVERTING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS,
    FiltterSlectedValue,
})
export const getNonConvertingAdsSelectedGropOptions = (FiltterSlectedValue) => ({
    type: actionTypes.GET_NON_COVERTING_ADS_SELECTED_GROP_OPTIONS,
    FiltterSlectedValue,
})
// ! -------------------------------- Google_Ads_Form_Submission_Date --------------------------------------------------
export const GetGoogleAdsAuthDate = (FiltterSlectedValue) => ({
    type: actionTypes.GOOGLE_ADS_FORM_SUBMISSION_DATE,
    FiltterSlectedValue,
})
// ! -------------------------------- Meta_Ads_Form_Submission_Date --------------------------------------------------
export const GetMetaAdsAuthDate = (FiltterSlectedValue) => ({
    type: actionTypes.META_ADS_FORM_SUBMISSION_DATE,
    FiltterSlectedValue,
})

//!------------------ facebook_Search_Box  -----------------------
export const GetFacebookSearchBox = (FacebookSearchBoxValue) => ({
    type: actionTypes.FACEBOOK_SEARCH_BOX,
    FacebookSearchBoxValue,
})

// !----------------------------------------------------------------
// Select text center id
export const onChangeTextCenter = (data) => ({
    type: actionTypes.ON_CHANGE_TEXT_CENTER,
    data
})

// Coommon handle change
export const commonHandleChange = (syntheticEvent) => ({
    type: actionTypes.COMMON_HANDLE_CHANGE,
    syntheticEvent
})

// On apply filter
export const onApplyDateFilter = () => ({
    type: actionTypes.ON_APPLY_DATE_FILTER
})

export const changeCurrentPage = (data) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    data,
})


